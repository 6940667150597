// import { transparentize } from 'polished'
import React from 'react'
import styled, {
  ThemeProvider as StyledComponentsThemeProvider,
  createGlobalStyle,
  css,
  DefaultTheme
} from 'styled-components'
import { Text, TextProps } from 'rebass'
import { Colors } from './styled'
import BackgroundImage from '../assets/images/background.png'
import LibertadMonoBlackWoff from './libertad-mono/LibertadMono-Black.woff'
import LibertadMonoBlackWoff2 from './libertad-mono/LibertadMono-Black.woff2'
import LibertadMonoBoldWoff from './libertad-mono/LibertadMono-Bold.woff'
import LibertadMonoBoldWoff2 from './libertad-mono/LibertadMono-Bold.woff2'
import LibertadMonoLightWoff from './libertad-mono/LibertadMono-Light.woff'
import LibertadMonoLightWoff2 from './libertad-mono/LibertadMono-Light.woff2'
import LibertadMonoMediumWoff from './libertad-mono/LibertadMono-Medium.woff'
import LibertadMonoMediumWoff2 from './libertad-mono/LibertadMono-Medium.woff2'
import LibertadMonoRegularWoff from './libertad-mono/LibertadMono-Regular.woff'
import LibertadMonoRegularWoff2 from './libertad-mono/LibertadMono-Regular.woff2'
import LibertadMonoThinWoff from './libertad-mono/LibertadMono-Thin.woff'
import LibertadMonoThinWoff2 from './libertad-mono/LibertadMono-Thin.woff2'

export * from './components'

const MEDIA_WIDTHS = {
  upToExtraSmall: 500,
  upToSmall: 720,
  upToMedium: 960,
  upToLarge: 1280
}

const mediaWidthTemplates: { [width in keyof typeof MEDIA_WIDTHS]: typeof css } = Object.keys(MEDIA_WIDTHS).reduce(
  (accumulator, size) => {
    ;(accumulator as any)[size] = (a: any, b: any, c: any) => css`
      @media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
        ${css(a, b, c)}
      }
    `
    return accumulator
  },
  {}
) as any

const white = '#FFFFFF'
const black = '#000000'

export function colors(): Colors {
  return {
    // base
    white,
    black,

    // text
    text1: '#AAAAAA',
    text2: '#D2DE3D',
    text3: '#6C7284',
    text4: '#565A69',
    text5: '#2C2F36',

    // backgrounds / greys
    bg1: '#000',
    bg2: '#0f0f0f',
    bg3: '#eeeeee',
    bg4: '#565A69',
    bg5: '#6C7284',

    //specialty colors
    modalBG: 'rgba(0,0,0,.425)',
    advancedBG: 'rgba(0,0,0,0.1)',

    //primary colors
    primary1: '#272018',
    primary2: '#3680E7',
    primary3: '#4D8FEA',
    primary4: '#376bad70',
    primary5: '#eeeeee',

    // color text
    primaryText1: '#000',

    // secondary colors
    secondary1: '#eeeeee',
    secondary2: '#17000b26',
    secondary3: '#17000b26',

    // other
    red1: '#FD4040',
    red2: '#F82D3A',
    red3: '#D60000',
    green1: '#27AE60',
    yellow1: '#FFE270',
    yellow2: '#d2de3d',
    yellow3: '#D2DE3D',
    blue1: '#eeeeee',
    gray: '#AEAEAE'

    // dont wanna forget these blue yet
    // blue4: '#153d6f70',
    // blue5: '#153d6f70',
  }
}

export function theme(): DefaultTheme {
  return {
    ...colors(),

    grids: {
      sm: 8,
      md: 12,
      lg: 24
    },

    //shadows
    shadow1: '#000',

    // media queries
    mediaWidth: mediaWidthTemplates,

    // css snippets
    flexColumnNoWrap: css`
      display: flex;
      flex-flow: column nowrap;
    `,
    flexRowNoWrap: css`
      display: flex;
      flex-flow: row nowrap;
    `
  }
}

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  return <StyledComponentsThemeProvider theme={theme()}>{children}</StyledComponentsThemeProvider>
}

const TextWrapper = styled(Text)<{ color: keyof Colors }>`
  color: ${({ color, theme }) => (theme as any)[color]};
`

export const TYPE = {
  main(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text2'} {...props} />
  },
  link(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'primary1'} {...props} />
  },
  black(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text1'} {...props} />
  },
  white(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'white'} {...props} />
  },
  body(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={16} color={'text1'} {...props} />
  },
  largeHeader(props: TextProps) {
    return <TextWrapper fontWeight={600} fontSize={24} {...props} />
  },
  mediumHeader(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={20} {...props} />
  },
  subHeader(props: TextProps) {
    return <TextWrapper fontWeight={400} fontSize={14} {...props} />
  },
  small(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={11} {...props} />
  },
  blue(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'blue1'} {...props} />
  },
  yellow(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  yellow1(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'yellow1'} {...props} />
  },
  darkGray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'text3'} {...props} />
  },
  lightgray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'gray'} {...props} />
  },
  gray(props: TextProps) {
    return <TextWrapper fontWeight={500} color={'bg3'} {...props} />
  },
  italic(props: TextProps) {
    return <TextWrapper fontWeight={500} fontSize={12} fontStyle={'italic'} color={'text2'} {...props} />
  },
  error({ error, ...props }: { error: boolean } & TextProps) {
    return <TextWrapper fontWeight={500} color={error ? 'red1' : 'text2'} {...props} />
  }
}

export const FixedGlobalStyle = createGlobalStyle`
html, input, textarea, button {
  font-family: 'Inter', sans-serif;
  font-display: fallback;
}
@supports (font-variation-settings: normal) {
  html, input, textarea, button {
    font-family: 'Inter', sans-serif;
  }
}

html,
body {
  margin: 0;
  padding: 0;
}

 a {
   color: ${colors().blue1}; 
 }

* {
  box-sizing: border-box;
}

button {
  user-select: none;
}

html {
  font-size: 16px;
  font-variant: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  font-feature-settings: 'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on;
  background: url(${BackgroundImage});
  background-size: cover;
  background-postion: center;
  background-repeat:no-repeat;
}
`

export const ThemedGlobalStyle = createGlobalStyle`
html {
  color: ${({ theme }) => theme.text1};
  background-color: ${({ theme }) => theme.bg2};
}

body {
  min-height: 100vh;
  background-position: 0 -30vh;
  background-repeat: no-repeat;
  font-family: 'Libertad Mono';
}

button {
  font-family: 'Libertad Mono';
}

@font-face {
  font-family: 'Libertad Mono';
  @font-face {
    font-family: 'Libertad Mono';
    src: url('${LibertadMonoLightWoff2}') format('woff2'),
        url('${LibertadMonoLightWoff}') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libertad Mono';
    
    src: url('${LibertadMonoBlackWoff2}') format('woff2'),
        url('${LibertadMonoBlackWoff}') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libertad Mono';
   
    src: url('${LibertadMonoThinWoff2}') format('woff2'),
        url('${LibertadMonoThinWoff}') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libertad Mono';
    src: url('${LibertadMonoBoldWoff2}') format('woff2'),
        url('${LibertadMonoBoldWoff}') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libertad Mono';
    src: url('${LibertadMonoRegularWoff2}') format('woff2'),
        url('${LibertadMonoRegularWoff}') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Libertad Mono';
    src: url('${LibertadMonoMediumWoff2}') format('woff2'),
        url('${LibertadMonoMediumWoff}') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

}
`
