import { Text } from 'rebass'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem;
`

export const PoolDescription = styled.div`
  background-color: black;
  padding: 34px 30px;
  clip-path: polygon(0 0, 93% 0, 100% 29%, 100% 100%, 70% 100%, 7% 100%, 0 75%, 0% 38%);
`
export const ConnetWallet = styled.div`
  background-color: ${({ theme }) => theme.yellow2};
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 6% 100%, 0 70%);
`

export const ConnetWalletInner = styled.div`
  padding-top: 32px;
  padding-bottom: 32px;
  background-color: rgb(0 0 0 / 90%);
  margin: 1px;
  clip-path: polygon(0% 0%, 100% 0, 100% 100%, 6% 100%, 0 70%);
  position: relative;
  min-height: 77px;
`

export const ClickableText = styled(Text)`
  :hover {
    cursor: pointer;
  }
  color: ${({ theme }) => theme.primaryText1};
`
export const MaxButton = styled.button<{ width: string }>`
  padding: 0.5rem 1rem;
  background-color: ${({ theme }) => theme.primary5};
  border: 1px solid ${({ theme }) => theme.primary5};
  border-radius: 0px
  font-size: 1rem;
  ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0.25rem 0.5rem;
  `};
  font-weight: 500;
  cursor: pointer;
  margin: 0.25rem;
  overflow: hidden;
  color: ${({ theme }) => theme.primaryText1};
  :hover {
    border: 1px solid ${({ theme }) => theme.primaryText1};
  }
  :focus {
    border: 1px solid ${({ theme }) => theme.primaryText1};
    outline: none;
  }
`

export const Dots = styled.span`
  &::after {
    display: inline-block;
    animation: ellipsis 1.25s infinite;
    content: '.';
    width: 1em;
    text-align: left;
  }
  @keyframes ellipsis {
    0% {
      content: '.';
    }
    33% {
      content: '..';
    }
    66% {
      content: '...';
    }
  }
`
