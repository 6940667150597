import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div`
  position: relative;
  max-width: 420px;
  width: 100%;
  border-radius: 4px;
  background: #000;
  clip-path: ${({ clip }: { clip?: boolean }) => (clip ? 'polygon(0% 0%, 88% 0, 100% 10%, 100% 100%, 0% 100%)' : '')};
  /* padding: 1rem; */
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, clip }: { clip?: boolean; children: React.ReactNode }) {
  return <BodyWrapper clip={clip}>{children}</BodyWrapper>
}
