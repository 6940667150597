import React from 'react'
import styled from 'styled-components'
import Settings from '../Settings'
import { RowBetween } from '../Row'
import { TYPE } from '../../theme'

const StyledSwapHeader = styled.div`
  padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  margin-bottom: -4px;
  width: 100%;
  max-width: 420px;
  color: ${({ theme }) => theme.bg2};
`

export default function SwapHeader() {
  return (
    <StyledSwapHeader>
      <RowBetween>
        <TYPE.lightgray fontWeight={500}>Swap</TYPE.lightgray>
        <Settings />
      </RowBetween>
    </StyledSwapHeader>
  )
}
